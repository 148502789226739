<template>
  <div class="TopBar">
    <div class="Logo">
      <img
        src="@/assets/micron_white.png"
        style="max-height: 40px; padding-top: 10px"
        alt="Micron Logo"
      />
    </div>
    <div class="Content">
      <div class="Left">
        <div class="Option mbl-only" v-on:click="OpenNav">
          <span class="icon icon-paragraph-justify"></span>
        </div>
      </div>
      <div class="Right">
        <div class="Option" v-on:click="Logout">
          <span class="icon icon-exit"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="Navigator" :class="ShowNav === true ? 'OpenNav' : ''">
    <div class="Seperator SeperateLarge">Hi, {{ FullName }}</div>
    <router-link to="/Home" class="Option">
      <span class="icon icon-house_menu"></span>
      Home
    </router-link>
    <div class="Seperator">Tests</div>
    <router-link to="/Results" class="Option">
      <span class="icon icon-task_list"></span>
      Results
    </router-link>
    <div class="Seperator" v-if="HasOrganisation === true">Organisations</div>
    <div v-if="HasOrganisation === true">
      <router-link
        v-for="row in Organisations"
        :key="row.organisation_id"
        :to="{ name: 'Organisation', params: { id: row.organisation_id } }"
        class="Option"
      >
        {{ row.name }}
      </router-link>
    </div>
    <div class="Seperator" v-if="isStaff === true">Staff</div>
    <div class="Seperator" v-if="isQc === true">Staff</div>
    <div class="Seperator" v-if="isTester === true">Staff</div>
    <router-link to="/Admin/Analytics" class="Option" v-if="isStaff === true">
      Analytics
    </router-link>
    <router-link to="/Admin/Users" class="Option" v-if="isStaff === true">
      Users
    </router-link>
    <router-link to="/Admin/Tests" class="Option" v-if="isStaff === true">
      Tests
    </router-link>

    <router-link to="/Admin/Tests" class="Option" v-if="isQc === true">
      Tests
    </router-link>

    <router-link to="/Admin/Tests" class="Option" v-if="isTester === true">
      Tests
    </router-link>

    <router-link to="/Admin/Businesses" class="Option" v-if="isStaff === true">
      Businesses
    </router-link>
    <router-link
      to="/Admin/Organisations"
      class="Option"
      v-if="isStaff === true"
    >
      Organisations
    </router-link>
    <router-link to="/Admin/Finance" class="Option" v-if="isStaff === true">
      Finance
    </router-link>
    <router-link to="/Admin/GDPR" class="Option" v-if="isStaff === true">
      GDPR
    </router-link>
    <router-link to="/Admin/Settings" class="Option" v-if="isStaff === true">
      App Settings
    </router-link>
    <router-link to="/Admin/ShopSales" class="Option" v-if="isStaff === true">
      Shop Sales
    </router-link>
    <router-link to="/Admin/ShopProducts" class="Option" v-if="isStaff === true">
      Shop Products
    </router-link>
  </div>
  <div class="BreadCrumb">
    <router-link
      v-for="crumb in Breadcrumb"
      :key="crumb"
      :to="{ name: crumb.route }"
      >{{ crumb.name }}</router-link
    >
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "Navigation",
  props: {
    Breadcrumb: Array,
  },
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      ShowNav: false,
    };
  },
  computed: {
    FullName() {
      return this.store.state["User"].info.name;
    },
    isStaff() {
      return this.store.state["User"].info.is_staff;
    },
    isQc(){
      return this.store.state["User"].info.is_qc;
    },
    isTester(){
      return this.store.state["User"].info.is_tester;
    }
    ,
    avatar() {
      return this.store.state["User"].info.avatar;
    },
    HasOrganisation() {
      return this.store.state["User"].organisation;
    },
    HasBusiness() {
      return this.store.state["User"].business;
    },
    Organisations() {
      return this.store.state["User"].organisations;
    },
  },
  methods: {
    OpenNav() {
      if (this.ShowNav === true) {
        this.ShowNav = false;
      } else {
        this.ShowNav = true;
      }
    },
    Logout() {
      this.store.dispatch("User/Logout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
  created() {
    this.store
      .dispatch("User/CheckAuth")
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        this.$router.push({ name: "Login" });
        console.log(err);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.TopBar {
  height: 60px;
  width: 100%;
  display: block;
  background: #04a394;
  position: fixed;
  z-index: 1000;
}

.TopBar .Logo {
  width: 320px;
  height: 60px;
  display: flex;
  position: fixed;
  background: #04a394;
}

.TopBar .Logo img {
  width: auto;
  height: auto;
  max-height: 60px;
  display: flex;
  margin: 0 auto;
  padding: 5px;
}

.TopBar .Content {
  display: block;
  width: calc(100% - 320px);
  padding-left: 320px;
  height: 100%;
}

.TopBar .Content .Left {
  width: auto;
  float: left;
  display: flex;
}

.TopBar .Content .Right {
  width: auto;
  float: right;
  display: flex;
  margin-right: 20px;
}

.TopBar .Content .Right .Option {
  display: inline-block;
  padding: 0px 20px 0px 20px;
  line-height: 60px;
  font-size: 26px;
  color: #efefef;
  cursor: pointer;
  border-right: none;
  border-left: 1px solid #21ad95;
}

.TopBar .Content .Option {
  display: inline-block;
  padding: 0px 20px 0px 20px;
  line-height: 60px;
  font-size: 26px;
  color: #efefef;
  cursor: pointer;
  border-right: 1px solid #21ad95;
}

.TopBar .Content .Option .Right {
  display: inline-block;
  padding: 0px 12px 0px 12px;
  line-height: 60px;
  font-size: 26px;
  color: #efefef;
  cursor: pointer;
  border-left: 1px solid #21ad95;
}

.BreadCrumb {
  width: auto;
  height: 30px;
  background: #efefef;
  padding-top: 60px;
  margin-left: 320px;
  border-bottom: 1px solid #cecece;
  line-height: 30px;
  font-size: 16px;
  padding-left: 0px 10px;
}

.BreadCrumb a {
  color: #0e836e;
  padding: 0px 10px 0px 10px;
  border-right: 1px solid #cecece;
  text-decoration: underline;
}

.BreadCrumb a:last-child {
  color: #4d4d4d;
  padding: 0px 10px 0px 10px;
  border-right: 1px solid #cecece;
  text-decoration: none;
}

.Navigator {
  width: 320px;
  height: calc(100vh - 60px);
  margin-top: 60px;
  position: fixed;
  background: #fff;
  border-right: 1px solid #efefef;
  z-index: 1000;
  overflow-y: auto;
}

.Navigator::-webkit-scrollbar {
  display: none;
}

.Navigator .Seperator {
  background: #04a394;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #fff;
}

.Navigator .SeperateLarge {
  background: #04a394;
  text-align: center;
  font-size: 18px;
  padding: 8px;
  color: #fff;
}

.Navigator .Option {
  width: auto;
  height: auto;
  padding: 15px 8px;
  text-align: left;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  background: #fff;
  border-right: 5px solid #fff;
  color: #4d4d4d;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  display: block;
}

.Navigator .Option:hover {
  width: auto;
  height: auto;
  padding: 15px 8px;
  text-align: left;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  color: #4d4d4d;
  border-right: 5px solid #0e836e;
  transition: 0.2s ease-in-out;
  background: rgba(58, 187, 164, 0.3);
}

.Navigator .router-link-exact-active {
  width: auto;
  height: auto;
  padding: 15px 8px;
  text-align: left;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  border-right: 5px solid #0e836e;
}

.Navigator .router-link-active {
  width: auto;
  height: auto;
  padding: 15px 8px;
  text-align: left;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  border-right: 5px solid #0e836e;
}

.OpenNav {
  left: 0px;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .Navigator {
    left: -100%;
    width: 100%;
  }

  .TopBar .Logo {
    width: 100px;
  }

  .TopBar .Content {
    width: calc(100% - 100px);
    padding-left: 100px;
  }

  .OpenNav {
    left: 0px;
    transition: 0.2s ease-in-out;
  }

  .BreadCrumb {
    width: 100%;
    height: 30px;
    background: #efefef;
    padding-top: 60px;
    margin-left: 0px;
    border-bottom: 1px solid #cecece;
    line-height: 30px;
    font-size: 16px;
    padding-left: 0px 10px;
  }
}
</style>
